import React from "react";
class UMSelectRoleUsers extends React.Component {
    render() {
        return (
            <option value={this.props.roleDataVales} name="roleListDDOption">
                {this.props.roleDataVales}
            </option>
        );
    }
}
export default UMSelectRoleUsers;
