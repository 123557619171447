import React from "react";
import ContactDetails from "../EntityMaster/Onboarding/contactDetails/ContactDetails.jsx";

import "bootstrap/js/tab.js";

class OrganizationalContactDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    componentWillReceiveProps(nextProps) {}
    render() {
        return (
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <ContactDetails
                    entity="appCompany"
                    roles={[
                        "employee",
                        "manager",
                        "corporateadmin",
                        "vendoradmin",
                        "admin",
                    ]}
                />
            </div>
        );
    }
}
export default OrganizationalContactDetails;
