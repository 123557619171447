import React from "react";

class UMDelRolRow extends React.Component {
    render() {
        return (
            <option
                value={`remove$${this.props.roleDataVales}`}
                name="userListDDOption"
            >
                Remove {this.props.roleDataVales} Role to Selected{" "}
            </option>
        );
    }
}

export default UMDelRolRow;
