import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route } from "react-router-dom";

//========  layoutPostLoginis HOC  ===========

import layoutPostLogin from "../revamp/CoreLayout/layoutPostLogin.js";

import EditUserProfile from "../revamp/userManagement/UM/EditUserProfile.js";
import UMRolesList from "../revamp/userManagement/Roles/UMRolesList.js";

const UMRolesListPage = layoutPostLogin(UMRolesList);
const EditUserProfilePage = layoutPostLogin(EditUserProfile);

export const routes = (
    <div className="col-lg-10 col-lg-offset-2">
        <Router>
            <Route path="/umroleslist" component={UMRolesListPage} exact />
            <Route
                path="/edituserprofile"
                component={EditUserProfilePage}
                exact
            />
        </Router>
    </div>
);
