/*import React  from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
import swal from 'sweetalert';
import axios from 'axios';
import './ResetPassword.css';

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerData: {
                title: "MY SHOPPING CART",
                breadcrumb: 'My Shopping Cart',
                backgroungImage: '/images/cartBanner.png',
            },
            showMessage : false
        }
    }
    componentDidMount(){
        this.validation();
    }
    resetPassword(event) {
        event.preventDefault();
        var userID = this.props.match.params.user_ID;
        var formValues = {
            "pwd" : this.refs.newPassword.value
        }
        if($('#resetPassword').valid()){
            $('.fullpageloader').show();
            axios.patch('/api/auth/patch/change_password_withoutotp/id/'+userID, formValues)
            .then((response)=>{
                $('.fullpageloader').hide();
                this.setState({
                    "showMessage" : true,
                })
                swal(response.data.message);
                this.props.history.push('/login');
            })
            .catch((error)=>{
                $('.fullpageloader').hide();
            })
        }
    }

    Closepagealert(event){
        event.preventDefault();
        $(".toast-error").html('');
        $(".toast-success").html('');
        $(".toast-info").html('');
        $(".toast-warning").html('');
        $(".toast-error").removeClass('toast');
        $(".toast-success").removeClass('toast');
        $(".toast-info").removeClass('toast');
        $(".toast-warning").removeClass('toast');
    }

    validation(){
        jQuery.validator.setDefaults({
            debug: true,
            success: "valid"
        });

        $("#resetPassword").validate({
            rules: {
                newPassword: {
                    required: true,
                },
                confirmPassword: {
                    required: true,
                    equalTo : "#newPassword"
                },
            },
            messages:{
                confirmPassword:"Passwords do not match"
            },
            errorPlacement: function (error, element) {
                if (element.attr("name") === "newPassword") {
                    error.insertAfter("#newPasswordmsg");
                }
                if (element.attr("name") === "confirmPassword") {
                    error.insertAfter("#confirmPass");
                }
            }
        });
    }
    showNewPass(){
        $('.showPwd').toggleClass('showPwd1');
        $('.hidePwd').toggleClass('hidePwd1');
        return $('#newPassword').attr('type', 'text');
    }
    hideNewPass(){
        $('.showPwd').toggleClass('showPwd1');
        $('.hidePwd').toggleClass('hidePwd1');
        return $('#newPassword').attr('type', 'password');
    }
    showConfirmPass(){
        $('.showPwd2').toggleClass('showPwd3');
        $('.hidePwd2').toggleClass('hidePwd3');
        return $('#confirmPassword').attr('type', 'text');
    }
    hideConfirmPass(){
        $('.showPwd2').toggleClass('showPwd3');
        $('.hidePwd2').toggleClass('hidePwd3');
        return $('#confirmPassword').attr('type', 'password');
    }
    render() {
        return (
            <div style={{'height': window.innerHeight+'px', 'width': window.innerWidth+'px'}} className="col-lg-12 col-md-12 col-sm-12 col-xs-12 LoginWrapper">
                <div className="col-lg-4 col-lg-offset-4 col-md-4 col-md-offset-7 col-sm-12 col-xs-12 formShadow mb100 mt100">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 innloginwrap">
                        <h3>Reset Password</h3>
                    </div>
                    {
                        this.state.showMessage === false ? 
                        <div>
                            <form id="resetPassword">
                            <div className="form-group textAlignLeft col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <label>New Password </label><label className="astricsign">*</label>
                                <input type="password" id="newPassword" className="form-control col-lg-12 col-md-12 col-sm-12 col-xs-12" ref="newPassword" name="newPassword" />
                                <div className="showHideSignDiv">
                                    <i className="fa fa-eye showPwd showEyeupSign" aria-hidden="true" onClick={this.showNewPass.bind(this)}></i>
                                    <i className="fa fa-eye-slash hidePwd hideEyeSignup " aria-hidden="true" onClick={this.hideNewPass.bind(this)}></i>
                                </div> 
                                <br/>
                                <div  id="newPasswordmsg"></div>
                            </div>
                            <div className="form-group textAlignLeft col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                                <label>Confirm Password</label><label className="astricsign">*</label>
                                <input type="password" id="confirmPassword" className="form-control col-lg-12 col-md-12 col-sm-12 col-xs-12" ref="confirmPassword" name="confirmPassword" />
                                <div className="showHideSignDiv">
                                    <i className="fa fa-eye showPwd2 showEyeupSign" aria-hidden="true" onClick={this.showConfirmPass.bind(this)}></i>
                                    <i className="fa fa-eye-slash hidePwd2 hideEyeSignup" aria-hidden="true" onClick={this.hideConfirmPass.bind(this)}></i>
                                </div> 
                                <br/>
                                <div id="confirmPass"></div>
                            </div>
                            <div className="col-lg-6 col-lg-offset-3 col-md-6 col-md-offset-3 col-sm-12 col-xs-12 mt25 mb25">
                                <button className="btn resetBtn" onClick={this.resetPassword.bind(this)}>Reset Password</button>
                            </div>
                        </form>
                        </div>
                        :
                        <div>
                            <p className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt25 textAlignCenter">Your password has been reset successfully!</p>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt10">
                                <div className="row loginforgotpass textAlignCenter"> Please &nbsp;
                                    <a href='/login' className=""><b>Click here</b></a> to Sign In.
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default ResetPassword;*/

import React from "react";
import $ from "jquery";
//import jQuery from 'jquery';
import swal from "sweetalert";
import axios from "axios";
import "./ResetPassword.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//import { withRouter }   from 'react-router-dom';
import * as mapActionCreator from "../common/actions/index";

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: "",
            confirmNewPassword: "",
            showPassword2: false,
            showPassword3: false,
        };
    }
    componentDidMount() {}

    showPassword2 = (event) => {
        event.preventDefault();
        var passwordToggle2 = document.getElementById("newPassword");
        if (passwordToggle2.type === "password") {
            passwordToggle2.type = "text";
            this.setState({ showPassword2: true });
        } else {
            passwordToggle2.type = "password";
            this.setState({ showPassword2: false });
        }
    };

    showPassword3 = (event) => {
        event.preventDefault();
        var passwordToggle3 = document.getElementById("confirmNewPassword");
        if (passwordToggle3.type === "password") {
            passwordToggle3.type = "text";
            this.setState({ showPassword3: true });
        } else {
            passwordToggle3.type = "password";
            this.setState({ showPassword3: false });
        }
    };

    validateForm = () => {
        var status = true;

        // var newPassword=this.state.newPassword;
        // var confirmNewPassword =this.state.confirmNewPassword;

        if (this.state.newPassword === null) {
            document.getElementById("newPasswordError").innerHTML =
                "Please enter Password";
            status = false;
        }

        if (this.state.newPassword.length < 8) {
            document.getElementById("newPasswordError").innerHTML =
                "Please enter atleast 8 characters";
            status = false;
        } else {
            document.getElementById("newPasswordError").innerHTML = "";
            status = true;
        }

        if (this.state.confirmNewPassword === null) {
            document.getElementById("confirmNewPasswordError").innerHTML =
                "Please enter Confirm Password";
            status = false;
        }

        if (this.state.confirmNewPassword.length < 8) {
            document.getElementById("confirmNewPasswordError").innerHTML =
                "Please enter atleast 8 characters";
            status = false;
        } else {
            document.getElementById("confirmNewPasswordError").innerHTML = "";
            status = true;
        }

        if (this.state.newPassword !== this.state.confirmNewPassword) {
            document.getElementById("newPasswordError").innerHTML =
                "Passwords do not match";
            document.getElementById("confirmNewPasswordError").innerHTML =
                "Passwords do not match";
            status = false;
        }

        return status;
    };
    handleChange(event) {
        var fieldValue = event.currentTarget.value;
        // console.log("fieldValue",fieldValue);
        var fieldKey = event.currentTarget.name;
        this.setState({
            [fieldKey]: fieldValue,
        });
    }

    logout() {
        var userDetails = localStorage.removeItem("userDetails");
        //alert()
        if (userDetails !== null && userDetails !== "undefined") {
            this.setState({
                loggedIn: false,
            });
        }
        window.location.href = "/";
        //this.props.history.push("/")
    }

    resetPassword(event) {
        event.preventDefault();

        var status = this.validateForm();
        if (status === true) {
            var body = {
                pwd: this.state.newPassword,
                user_id: this.props.userDetails.user_id,
            };

            console.log(this.state.newPassword);
            console.log(this.props.userDetails.user_id);
            console.log(body);
            if (this.state.newPassword === this.state.confirmNewPassword) {
                axios
                    .patch(
                        "/api/auth/patch/change_password_withoutotp/id",
                        body
                    )
                    .then((response) => {
                        swal(" ", "Your Password has been changed");
                        this.setState({
                            newPassword: "",
                            confirmNewPassword: "",
                        });
                        this.props.history.push("/login");
                    })
                    .catch((error) => {
                        console.log("error", error);
                    });
            } else {
                swal(
                    "Invalid Password",
                    "Please Enter valid New password and confirm password"
                );
            }
        }
    }

    Closepagealert(event) {
        event.preventDefault();
        $(".toast-error").html("");
        $(".toast-success").html("");
        $(".toast-info").html("");
        $(".toast-warning").html("");
        $(".toast-error").removeClass("toast");
        $(".toast-success").removeClass("toast");
        $(".toast-info").removeClass("toast");
        $(".toast-warning").removeClass("toast");
    }

    showNewPass() {
        $(".showPwd").toggleClass("showPwd1");
        $(".hidePwd").toggleClass("hidePwd1");
        return $("#newPassword").attr("type", "text");
    }
    hideNewPass() {
        $(".showPwd").toggleClass("showPwd1");
        $(".hidePwd").toggleClass("hidePwd1");
        return $("#newPassword").attr("type", "password");
    }
    showConfirmPass() {
        $(".showPwd2").toggleClass("showPwd3");
        $(".hidePwd2").toggleClass("hidePwd3");
        return $("#confirmNewPassword").attr("type", "text");
    }
    hideConfirmPass() {
        $(".showPwd2").toggleClass("showPwd3");
        $(".hidePwd2").toggleClass("hidePwd3");
        return $("#confirmNewPassword").attr("type", "password");
    }
    render() {
        return (
            <section className="container-fluid resetPasswordWrapper">
                <div className="resetPassword col-lg-4 col-lg-offset-4">
                    <form>
                        <div className="resetPasswordTitle col-lg-12">
                            Reset Password
                        </div>

                        <hr className="resetPasswordHr" />
                        <div className="col-lg-12 form-group">
                            <div className="input-group">
                                <span className="input-group-addon resetPasswordInputIcon">
                                    <i className="fa fa-lock"></i>
                                </span>
                                <input
                                    type="password"
                                    id="newPassword"
                                    name="newPassword"
                                    placeholder="Enter new Password"
                                    ref="newPassword"
                                    value={this.state.newPassword}
                                    onChange={this.handleChange.bind(this)}
                                    className="form-control resetPasswordInputBox"
                                />
                                <span
                                    className="input-group-addon loginInputIcon3"
                                    onClick={this.showPassword2.bind(this)}
                                >
                                    <i
                                        className={
                                            this.state.showPassword2
                                                ? "fa fa-eye-slash"
                                                : "fa fa-eye"
                                        }
                                        value={this.state.showPassword2}
                                    ></i>
                                </span>
                            </div>
                            <span
                                id="newPasswordError"
                                className="errorMsg"
                            ></span>
                        </div>

                        <div className="col-lg-12 form-group">
                            <div className="input-group">
                                <span className="input-group-addon resetPasswordInputIcon">
                                    <i className="fa fa-lock"></i>
                                </span>
                                <input
                                    type="password"
                                    id="confirmNewPassword"
                                    name="confirmNewPassword"
                                    placeholder="Confirm new Password"
                                    value={this.state.confirmNewPassword}
                                    onChange={this.handleChange.bind(this)}
                                    className="form-control resetPasswordInputBox"
                                />
                                <span
                                    className="input-group-addon loginInputIcon3"
                                    onClick={this.showPassword3.bind(this)}
                                >
                                    <i
                                        className={
                                            this.state.showPassword3
                                                ? "fa fa-eye-slash"
                                                : "fa fa-eye"
                                        }
                                        value={this.state.showPassword3}
                                    ></i>
                                </span>
                            </div>
                            <span
                                id="confirmNewPasswordError"
                                className="errorMsg"
                            ></span>
                        </div>

                        <div className="col-lg-12 buttonWrapper">
                            <button
                                className="btn col-lg-12 buttonResetPassword"
                                onClick={this.resetPassword.bind(this)}
                            >
                                Change Password
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        selectedModal: state.selectedModal,
        userID: state.userID,
        userDetails: state.userDetails,
    };
};
const mapDispatchToProps = (dispatch) => ({
    mapAction: bindActionCreators(mapActionCreator, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
