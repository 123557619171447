import React from "react";
import $ from "jquery";

import Header from "../common/header/Header.js";

//import Footer from '../common/footer/Footer.js'
//import Dashboard from '../dashboard/Dashboard.js'
import Leftsidebar from "../common/leftSidebar/Leftsidebar.js";

const layoutPostLogin = function (MainComponent) {
    return class Layout extends React.Component {
        constructor(props) {
            super();
            this.state = {
                loggedIn: false,
            };
        }

        componentDidMount() {
            $(document).ready(function () {
                $("#sidebarCollapse").on("click", function () {
                    $("#sidebar").toggleClass("active");
                });
            });
            $(document).ready(function () {
                $("#sidebarCollapse").on("click", function () {
                    $("#headerid").toggleClass("headereffect");
                });
            });
            $(document).ready(function () {
                $("#sidebarCollapse").on("click", function () {
                    $("#dashbordid").toggleClass("dashboardeffect");
                });
            });

            const token = localStorage.getItem("token");
            if (token != null && token !== undefined) {
                this.setState({
                    loggedIn: true,
                });
            } else {
            }
        }

        logout() {
            var token = localStorage.removeItem("token");
            if (token != null && token !== undefined) {
                this.setState({
                    loggedIn: false,
                });
            }
        }

        render() {
            if (this.state.loggedIn === false) {
                return (
                    <div className="App container-fluid">
                        <h1> hello world! </h1>
                        <div className="row">
                            <div
                                id="headerid"
                                className="headerbackgroundcolor "
                            >
                                <div className="">
                                    <Header />
                                </div>
                            </div>
                            <div className="">
                                <div id="dashbordid" className="">
                                    <button
                                        className="btn btn-primary pull-right"
                                        onClick={this.logout.bind(this)}
                                    >
                                        Logout
                                    </button>
                                    <MainComponent />
                                </div>
                            </div>
                            <div className="leftsidebarbackgroundcolor">
                                <div className="row">
                                    <Leftsidebar />
                                </div>
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-lg-offset-2 col-md-offset-2 col-sm-offset-2 col-xs-offset-2">
                                <div className=""></div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div>
                        <h1> Logged In </h1>
                    </div>
                );
            }
        }
    };
};

export default layoutPostLogin;
