import React from "react";

import "bootstrap/js/tab.js";
import StatutoryDetails from "../EntityMaster/Onboarding/basicInfo/StatutoryDetails.js";

function OrganizationalStatutoryDetails() {
    return (
        <div className="">
            <StatutoryDetails entity="appCompany" />
        </div>
    );
}
export default OrganizationalStatutoryDetails;
