import React from "react";

import "./TitleLayout.css";

class TitleLayout extends React.Component {
    render() {
        return (
            <div className="col-lg-12 titlelayoutWrapper">
                <div className="row">
                    <div className="BasicInfoTitle col-lg-12">
                        {this.props.title}
                        <span className="BasicInfoStep pull-right">
                            Step {this.props.pageNumber}/5
                        </span>
                    </div>

                    <div className="BasicInfoSubTitle">
                        {this.props.subtitle}
                    </div>
                </div>
            </div>
        );
    }
}

export default TitleLayout;
