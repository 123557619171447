import React, { Component } from "react";
import ReactPlayer from "react-player";
import Modal from "react-responsive-modal";

class Player extends Component {
    render() {
        const { open, toggleModal } = this.props;
        return (
            <Modal
                open={open}
                onClose={toggleModal}
                styles={{
                    modal: {
                        maxWidth: "unset",
                        width: "100%",
                        padding: "unset",
                    },
                    overlay: {
                        background: "rgba(0, 0, 0, 0.5)",
                    },
                    closeButton: {
                        background: "yellow",
                    },
                }}
                center
            >
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=r29CFLctWIY"
                    width="90%"
                    height="400px"
                />
            </Modal>
        );
    }
}

export default Player;
