import React from "react";

import BasicInfo from "../EntityMaster/Onboarding/basicInfo/BasicInfo.jsx";

import "bootstrap/js/tab.js";

class OrgnizationalBasicInfo extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    componentWillReceiveProps(nextProps) {}
    render() {
        return (
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <BasicInfo entity="appCompany" />
            </div>
        );
    }
}
export default OrgnizationalBasicInfo;
