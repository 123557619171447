import React from "react";
import "./BasicLayout.css";
import MenuDiv from "../../common/MenuDiv/MenuDiv.js";
import TitleLayout from "../../blocks/TitleLayout/TitleLayout.js";
import Academics from "../../blocks/Academics/Academics.js";

class CandidateAcademics extends React.Component {
    render() {
        return (
            <div className="mainPagesWrapper col-lg-12">
                <div className="row">
                    <div className="col-lg-12">
                        <MenuDiv />
                    </div>
                    <div className="col-lg-10 col-lg-offset-1 basicInfoWrapper row">
                        <div className=" col-lg-12 BasicInfoBlock row">
                            <TitleLayout
                                title="Academics"
                                subtitle="Education Qualification"
                                pageNumber="3"
                            />
                            <Academics />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CandidateAcademics;
