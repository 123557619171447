import React from "react";

class UMAddRolRow extends React.Component {
    render() {
        //var name = this.props.roleDataVales;
        return (
            <option
                value={`add$${this.props.roleDataVales}`}
                name="userListDDOption"
            >
                Add {this.props.roleDataVales} Role to Selected{" "}
            </option>
        );
    }
}

export default UMAddRolRow;
