import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./revamp/css/root.css";
import Layout from "./Layout.js";
import "./lib/router.js";
import axios from "axios";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import ReactDependentScript from "react-dependent-script";

import "./revamp/common/FontAwesomeIcons";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

export const App = () => {
    const [initMap, setInitMap] = useState(false);
    const [googleAPIKey, setGoogleAPIKey] = useState();
    useEffect(() => {
        axios
            .get("/api/projectSettings/get/GOOGLE")
            .then((response) => {
                console.log(
                    "Response....................................................",
                    response
                );
                setGoogleAPIKey(response.data.googleapikey);
                window.initMap = setInitMap(true);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <div>
            {initMap ? (
                <ReactDependentScript
                    loadingComponent={<div>Google API is loading...</div>}
                    scripts={[
                        "https://maps.googleapis.com/maps/api/js?key=" +
                            googleAPIKey +
                            "&region=IN&v=3.exp&libraries=geometry,drawing,places",
                    ]}
                >
                    <Layout />
                </ReactDependentScript>
            ) : null}
        </div>
    );
};
