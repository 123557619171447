import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import saveAs from "file-saver";
import Player from "./reactPlayer";
//import "./styles.css";

export default class Youtube extends React.Component {
    state = {
        open: false,
    };

    onOpenModal = () => {
        this.setState((prevState) => ({
            open: !prevState.open,
        }));
    };

    // don't do this

    render() {
        return (
            <div className="myModal">
                <button onClick={this.onOpenModal}>Play Video </button>
                <Player open={this.state.open} toggleModal={this.onOpenModal} />
            </div>
        );
    }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Youtube />, rootElement);
