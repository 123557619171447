import React from "react";
import "bootstrap/js/tab.js";

import ListOfEntities from "../EntityMaster/listOfEntities/components/ListOfEntities.jsx";

function CorporateListOfEntities() {
    return (
        <div className="">
            <ListOfEntities entity="corporate" />
        </div>
    );
}
export default CorporateListOfEntities;
